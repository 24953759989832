import { motion } from 'framer-motion';
import { slideIn } from "../utils/motion";
import { AiFillInstagram, AiFillYoutube, AiOutlineArrowRight, AiOutlineTwitter } from 'react-icons/ai';
import Button from '../utils/Button';
import { SiTelegram } from 'react-icons/si';

const Header = () => {

    return (<motion.div className=" w-full text-[#fff] py-5 my-2 md:py-1  text-[16px]  flex flex-col  md:flex-row z-20 gap-5 items-center  justify-between md:justify-between px-4 md:px-10"
        variants={slideIn('up', 'spring', 0.1, 0.4)}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}

    >
        <div className=' flex flex-row items-center justify-start rounded-full'>
   
            <h1 className='text-[40px] font-semibold'>$Schrödinger</h1>
        </div>
        <motion.div className='text-[#]'
        >
             <a href="https://t.me/SolSchrodingerCat"><Button scale="out" text="Join Our Community" classProps="text-[#43072A] font text-[22px] border-[#43072A] bg-[#65B5ED]" /></a>
        </motion.div>

    </motion.div>);
}

export default Header;