import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { loaderVariants, slideIn } from "../utils/motion";
import { FaTelegram } from "react-icons/fa";

import { AiFillTwitterCircle, AiOutlineCopy, AiOutlineArrowRight, AiOutlineTwitter, AiOutlineRight, AiOutlineWallet } from "react-icons/ai"
import Button from "../utils/Button";
import AnimeLogo from "../utils/AnimeLogo";
import CA from "../utils/Ca";
import GlowingHeader from "../utils/GlowingHeader";
import '../utils/glowing.css';
const Hero = ({ pryColor
    , secColor }
) => {


    const [isCopied, setIsCopied] = useState(false);
    const handleCopied = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000)
    }

    const contractRef = useRef();

    const copyContractText = () => {
        const text = contractRef.current.textContent;
        navigator.clipboard.writeText(text);
        console.log('Text copied: ' + text);
        handleCopied();
    };


    return (<div className=" relative hero-section w-[100vw]  flex mx-auto flex-col px-4 pt-0 gap-5">

        <div classname={`flex flex-col w-full items-center gap-10 justify-center`}>
            <div className="flex flex-col items-center w-full justify-center">
                <AnimeLogo imgUrl="logo.jpg" classProps={``} />
                <div className="relative md:mt-5">

                    <div className="absolute inset-0 bg-gradient-to-r from-neon-blue to-neon-teal opacity-75 rounded-lg shadow-md"></div>

                    <h1 className="relative z-10  text-[#fff] text-center leading-tight text-[70px]">$Schrödinger <span className="text-[#65B5ED] banger">- Quantum Cat</span></h1>
                </div>

            </div>
            <div className="text-[25px] text-center w-full text-[#f2f2f2]">
                <h1>Elon's Quantum Travelling dead or alive kitty</h1>
            </div>
            <div className="flex flex-row gap-5 md:mt-5  items-center justify-center">
                <a href="#"><Button scale="in" text="Love Cats" classProps="text-[#65B5ED] font text-[21px] border-[#65B5ED] bg-[#43072A]" /></a>
                <a href="https://t.me/SolSchrodingerCat"><Button scale="out" text="Telegram" classProps="text-[#43072A] font text-[22px] border-[#43072A] bg-[#65B5ED]" /></a>
            </div>
        </div>
    

    </div>);
}

export default Hero;