import { useState, useRef } from "react";

const CA = ({boxClass, textClass, contractaddress}) => {

    const [isCopied, setIsCopied] = useState(false);
    const handleCopied = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000)
    }

    const contractRef = useRef();

    const copyContractText = () => {
        const text = contractRef.current.textContent;
        navigator.clipboard.writeText(text);
        console.log('Text copied: ' + text);
        handleCopied();
    };

    return (
        <div onClick={copyContractText} className={`w-full ${boxClass} cursor-pointer flex flex-col items-center justify-center gap-2 p-1`}>
            <h1 ref={contractRef} className={` ${textClass}  text-[14px] md:text-[20px] font-bold`}  >{!isCopied ? contractaddress : 'Copied!'}</h1>
        </div>
    );
}

export default CA;