import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { fadeIn, slideIn } from "../utils/motion";
import { FaTelegram } from "react-icons/fa";
import { AiFillTwitterCircle, AiOutlineCopy } from "react-icons/ai"
import MarqueeImage from "../utils/MarqueeImage";
import CA from "../utils/Ca";
import Button from "../utils/Button";


const About = () => {

    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.5,
                staggerChildren: 0.5
            }
        }
    }

    const motionConfig = {
        variants: slideIn('spring'),
        initial: 'hidden',
        whileInView: 'visible',
        viewport: { once: true },
      };



    const taxData = [
        {
            title: 'Token Buybacks',
            body: 'The taxes collected from these transactions will be used to purchase large sums of other popular tokens, using advanced and redacted trading protocols.'
        },
        {
            title: "Token Appreciation",
            body: 'The purchased tokens will be held with the expectation that they appreciate in value. This appreciation could be due to various factors, such as market demand, adoption, or specific developments related to the tokens.',

        },
        {
            title: "Profit Generation",
            body: 'Once the purchased tokens have significantly appreciated in value, the $WATER protocol will sell those tokens, generating a profit from the investment.',
        },
        {
            title: "Main Token Burning",
            body: 'The profits obtained from selling the appreciated tokens will be utilized to buy back and burn $WATER. Token burning involves permanently removing tokens from circulation, which can potentially lead to increased scarcity and, in turn, impact the token\'s value positively.',
        }
    ]

    const [canPlay, setCanPlay] = useState(false);

    return (<div className="w-full relative   mt-10 py-10 md:spy-20 bg-[#00000084] ">
        <motion.div className="w-full py-10 px-4  text-[#fff] flex flex-col items-center mx-auto"
            variants={container}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
        >

            <div className="w-[90%] md:w-[80%] mx-auto text-center md:text-left flex flex-col gap-5 mb-5">
                <div className="flex gap-2 flex-row items-center justify-center ">
                    <motion.div
                        variants={slideIn('left', 'spring', 0.1, 0.4)}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    ><img src="../img/pic-2.jpeg" alt="boy" className="w-[100%]" /></motion.div>

                </div>
                <h1 className="text-6xl font-bold ">Tokenomics</h1>

            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3 text-center w-full">
                <div className="text-[#fff]">
                    <h1 className="text-6xl font-bold text-neon-blue">10 T</h1>
                    <p>Total Supply</p>
                </div>
                <div className="text-[#fff]">
                    <h1 className="text-6xl font-bold text-neon-blue">NO</h1>
                    <p>Max Wallet</p>
                </div>
                <div className="text-[#fff]">
                    <h1 className="text-6xl font-bold text-neon-blue">0%</h1>
                    <p>Buy Tax</p>
                </div>
                <div className="text-[#fff]">
                    <h1 className="text-6xl font-bold text-neon-blue">0%</h1>
                    <p>Sell Tax</p>
                </div>


            </div>



        </motion.div>


        <div className=" w-[90%] md:w-[80%] mx-auto my-5">




        </div>

        <div className="  text-[#fff] bg-opacity-80">
            <div className=" transform  bg-opacity-70 pb-5 flex  flex-col md:flex-row w-[90%] md:w-[80%] mx-auto justify-center items-center gap-10">
                <motion.div
                    variants={slideIn('left', 'spring', 0.1, 0.4)}
                    initial="hidden"
                    whileInView="visible"
                    className="transition hover:scale-105 duration-300"
                    viewport={{ once: true }}
                >
                    <img src="../img/logo.jpg" className="w-[500px] object-cover " alt="hero" />
                </motion.div>
                <div className="flex flex-col  gap-5 items-center justify-between px-4 w-[90%] md:w-[70%]">
                    <motion.div className="flex  flex-col gap-2 w-full items-center justify-center text-center"
                    >
                        <div className="flex flex-col gap-3">
                            <motion.h1 className="font-semibold text-[55px] md:text-[65px] uppercase leading-normal"
                              
                            >Buying $Schrödinger</motion.h1>
                            <motion.a href="#" target="_blank"
                              
                            >
                                <CA contractaddress="Contract Not Released" boxClass=" " textClass="font-light text-[#65B5ED]" />
                            </motion.a>
                            <motion.p className="w-full mx-auto"
                                variants={slideIn('down', 'spring', 0.4, 0.5)}
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                            >Schrödinger u geriatric prick, what have you done!

                                Something is happening i feel good man.


                            </motion.p>
                            <div className="flex flex-row-reverse gap-5  items-center justify-center">
                                <a href="https://x.com/SolSchrodingerCat"><Button scale="in" text="X" classProps="text-[#65B5ED] font text-[21px] border-[#65B5ED] bg-[#43072A]" /></a>
                                <a href="https://t.me/SolSchrodingerCat"><Button scale="out" text="Telegram" classProps="text-[#43072A] font text-[22px] border-[#43072A] bg-[#65B5ED]" /></a>
                            </div>
                        </div>

                    </motion.div>
                </div>

            </div>
        </div>

    </div>);
}

export default About;