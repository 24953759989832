import { Hero, Buy, WhyBob, RoadMap } from "../components";
import { useState } from "react";
import { motion } from 'framer-motion'
import { slideIn } from "../utils/motion";
import ScrollToTopButton from "../components/ScrollToTopBar";
import About from "../components/About";
import Tokenomics from "../components/Tokenomics";
import Uniswap from "../components/Uniswap";

const Home = ({ pryColor, secColor, interColor, divHeight, divWidth }) => {



    function scrollToDiv() {
        const myDiv = document.getElementById('#Tokenomics');
        myDiv.scrollIntoView({ behavior: 'smooth' });
    }

    const [viewToken, setViewToken] = useState();
    const [showMenu, setShowMenu] = useState(false);
    const handleMenu = () => {
        setShowMenu(prevState => !prevState);
    }

    return (<div className="  relative flex flex-col  mx-auto items-center justify-center ">
        <div style={{ height: divWidth >= 1024 ? divHeight : '' }} >
            <Hero
                pryColor={pryColor}
                secColor={secColor}
                interColor={interColor}

            />
        </div>

        <About
            pryColor={pryColor}
            secColor={secColor}
            interColor={interColor}
        />
      
        <motion.div className="absolute bottom-5 right-10 z-10 "
            variants={slideIn("up", "spring", 1.7, 0.5)}
            initial="hidden"
            whileInView="visible"
            exit="hidden"
        >
     
        </motion.div>


    </div>);
}

export default Home;